<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'


import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'


import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_domain = ref("reddit.com")
let request_offset = ref("")


let get_query = computed(() => {
  return "/query/domain/" + request_domain.value + "/subdomains" 
}) 

</script>

<template>
  <div>
    <doc-section title="Subdomains">
      <template v-slot:left>
        <div class="mb-5">
          Returns a list of subdomains for a fully qualified domain name.<br><br>

          Note however, that if you supply a subdomain, it will only list subdomains of that subdomain. The subdomains are returned in batches of 1000 (200 for free accounts), the offset query parameter can be used by paid accounts to cursor through the entire result set. 
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>

          <StringParam class="mb-3" name="Domain" :required="true" description="The domain you wish to find subdomains of" type="string" :value="request_domain" @value="(v) => request_domain = v" />
          <StringParam class="mb-3" name="offset" description="The domain you wish to find subdomains of" type="integer" :value="request_" @value="(v) => request_offset = v" />

          <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object array" description="The requested information">

            <BasicAttribute name="domain" type="string" description="The requested domain" />
            <hr>

            <BasicAttribute name="ips" type="object array" description="The IPs which the domain resolves to, or have, if it currently doesn't resolve to any ip">
              <BasicAttribute name="ip" type="string" description="An ip the domain has recently resolved to" />
              <hr>
              <BasicAttribute name="risk_score" type="integer" description="Webscout's determination of the risk associated with the IP. Displayed in the form of a number between 0-100" />
              <hr>
              <BasicAttribute name="country" type="string" description="Two letter country code" />
              <hr>
              <BasicAttribute name="network_provider" type="string" description="The entity managing the ip address" />
            </BasicAttribute>
            <hr>

            <BasicAttribute name="num_emails" type="integer" description="The number of email addresses we have opserved on the domain" />
            <hr>

            <BasicAttribute name="num_subdomains" type="integer" description="The number of subdomains we have found on the domain" />
            <hr>

            <BasicAttribute name="registrant_org" type="string" description="The organization that registered the domain" />
            <hr>

            <BasicAttribute name="registrant_email" type="string" description="The email used to register the domain" />
            <hr>

            <BasicAttribute name="registration_date" type="string" description="The date the domain was registered" />
            <hr>

            <BasicAttribute name="expiration_date" type="string" description="The date the domain registration expires" />
            <hr>

            <BasicAttribute name="behavior" type="string array" description="Activity we have observed related to the domain or it's infrastructure" />

          </BasicAttribute>


        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/domain/:domain/subdomains" method="GET" dark/>
      
        <json-block title="Response">
{
"status": "success",
"data": [
{
"domain": "0.reddit.com",
"ips": [
{
"ip": "146.75.121.140",
"risk_score": 0,
"country": "US",
"network_provider": "Fastly"
}
],
"num_emails": 0,
"num_subdomains": 0,
"registrant_org": null,
"registrant_email": null,
"registration_date": null,
"expiration_date": null,
"behavior": null
},
{
"domain": "00.reddit.com",
"ips": [
{
"ip": "146.75.121.140",
"risk_score": 0,
"country": "US",
"network_provider": "Fastly"
}
],
"num_emails": 0,
"num_subdomains": 0,
"registrant_org": null,
"registrant_email": null,
"registration_date": null,
"expiration_date": null,
"behavior": null
}]
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
</style>
