<script setup>
import {onMounted, computed, ref, watch } from 'vue'
import moment from 'moment'
import QueryExample from '@/components/Docs/QueryExample.vue'
import { useRouter } from 'vue-router'
const router = useRouter()

import { useQueryStore } from '@/stores/query'
let queryStore = useQueryStore()

import { useUserStore } from '@/stores/user'
let userStore = useUserStore()

onMounted(  async () => {
  userStore.details()
  userStore.getQueries()
})


function clear_query() {
  queryStore.result = null
  queryStore.error = ""
  return true
}

async function exec_query(input) {
  await queryStore.query(input)
  router.push({
    name: "UserQuery",
  })
}

</script>

<template>
  <div class="p-3">
      
    <div class="row">

      <div class="col-md-8">
        <div class="box padded mb-3">
          <div class="row mb-4">
            <div class="col-md-3">
              <div class="title usage">Requests today</div>
              <div><strong  style="font-size: 1.4rem;" class="white">{{ userStore.api_daily_spend }}</strong>&nbsp; / {{ userStore.limit_api_daily}}</div>
            </div>

            <div class="col-md-3">
              <div class="title usage">Requests this month</div>
              <div><strong  style="font-size: 1.4rem;" class="white">{{ userStore.api_monthly_spend }}</strong>&nbsp; / {{ userStore.limit_api_monthly}}</div>
            </div>

            <div class="col-md-3 ms-auto d-flex">
                <router-link to="/plans" class="button dark ms-auto" style="color: #d578f1; align-self: flex-end; padding: .5rem 2rem;">BASIC</router-link>
            </div>
          </div>

          <div>
            <!--
              <div class="d-flex" style="color: #d578f1;">
                <div>42%</div>
                <div class="ms-auto">1000</div>
              </div>
            -->
              <div class="progress" style="background-color: rgb(213 120 241 / 33%)" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar progress-bar-striped progress-bar-animated" :style="{width:  ((userStore.api_monthly_spend/userStore.limit_api_monthly)*100) + '%'}" style="background-color: #d578f1;"></div>
              </div>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-md-4 col-sm-6">
              <query-example class="mb-3" title="Example 1: Bulk IP enrichment" query="ip = 92.222.171.6,62.33.115.241,141.193.213.20,9.9.9.9,149.102.246.99" />
            </div> 
            <div class="col-md-4 col-sm-6">
              <query-example class="mb-3" title="Example 2: Domains resolving to 12.34.56.78" query="reverse_dns = 12.34.56.78" />
            </div> 
            <div class="col-md-4 col-sm-6">
              <query-example class="mb-3" title="Example 3: Domains similar to bt.dk" query="similar = bt.dk" />
            </div> 
          </div>
        </div>

        <div class="box med-padded mb-3">
          <div class="title mb-3">
            Recent searches
          </div>
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 20%">Time</th>
                  <th style="width: 70%">Query</th>
                  <th style="width: 10%">Credits Used</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="query in userStore.queries" :key="query">
                  <td >{{ moment(query.timestamp).fromNow() }}</td>
                  <td @click="exec_query(query.query)" class="query_history_cell">{{ query.query}}</td>
                  <td >{{ query.credits_used}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <div class="dropzone">
          <div><span class="white">Drag & drop a file or text</span></div>
          <!--
          <div class="mt-2 mb-2">or</div>
          <button class="file-button" >Choose file</button>
          -->
        </div>

      </div>

      <div class="col-md-4">

        <div class="box med-padded mb-3">
          <div class="title mb-3">
            Getting started
          </div>
          <p>
Webscout is an enrichment platform that helps threat hunters, soc analysts, incident responders,
and other cybersecurity professionals make sense of web selectors such as IP addresses and
domain names.</p>
          <p>
Check out our <router-link to="/docs/wiki">wiki</router-link> for practical use cases that demonstrate the platform's capabilities, or
familiarize yourself with the types of searches you can perform by checking our Query Reference.

          </p><p>
If you just want to jump right in, try entering the following query in the search bar above:
<code @click="exec_query('subdomains = reddit.com')" class="clickable-item">subdomains = reddit.com</code>. You can also drag-and-drop a file with IP addresses, domain names, or both
directly into the user interface.
For automation options, refer to our API Reference. Enjoy the platform and remember to hunt
responsibly 👽 📡
          </p>
          <div class="d-flex">

            <router-link to="/query" @click.prevent="clear_query" class="button dark me-3 d-flex vertically-centered">
              <img src="~@/assets/icons/file-code.svg" class="me-2" height="18" width="18">
              Query reference
            </router-link>
            <router-link to="/docs/api" class="button dark me-3 d-flex vertically-centered">
              <img src="~@/assets/icons/file-code.svg" class="me-2" height="18" width="18">
              API reference
            </router-link>
            <router-link to="/docs/wiki" class="button dark me-3 d-flex vertically-centered">
              <font-awesome-icon style="color: white; font-size: 1rem;" class="me-2" :icon="['fas', 'play']" />
              Getting started
            </router-link>

          </div>
        </div>

        <div class="box med-padded mb-3">
          <div class="title mb-3">
            Development updates
          </div>
          <div class="timeline">

            <div class="item"> <div class="d-flex mb-1"><strong class="white">New Hash/fingerprint queries and endpoints
</strong> <span class="ms-auto"><font-awesome-icon style="font-size: 1rem;" class="me-2" :icon="['fas', 'clock']" />{{ moment("11-19-2024 09:00:00").fromNow()}}</span></div>
              
              <p>
Last week we quietly rolled out “Hashes” - a new type of query and endpoint - allowing users to search through all the fingerprints webscout’s sensor network generates. Webscout currently supports most major connection type fingerprints, but more are in the works. 
              </p>
              <p>
Currently supported hashes are: JA3S, JA4, JA4SSH, JA4T, JA4H, JA4LS, JA4s, JA4TS, and Jarm
              </p>
              <p>
Consult the <router-link to="/docs/api">API documentation</router-link> for further details
              </p>
            </div>

            <div class="item"> <div class="d-flex mb-1"><strong class="white">Introducing the risk score</strong> <span class="ms-auto"><font-awesome-icon style="font-size: 1rem;" class="me-2" :icon="['fas', 'clock']" />{{ moment("10-23-2024 10:00:00").fromNow()}}</span></div>
              
              <p> 
We are excited to announce the “risk score” as a new addition to the output of all IP requests and queries. The risk score is Webscout’s attribution of danger to any given IP address based on its reputation, or that of its related entities as seen from a defender's point of view. The score is a number between 0 and 100 - with 0 being neutral and 100 being dangerous.
              </p>
              <p>
              In the UI these numbers will be displayed using a gradient as follows 
              </p>
              <div class="d-flex" id="risk_gradients">
                  <div class="d-flex column centered">
                    <img src="~@/assets/icons/neutral.svg"/>
                    <div>0-3</div>
                  </div>

                  <div class="d-flex column centered">
                    <img src="~@/assets/icons/small.svg"/>
                    <div>4-9</div>
                  </div>

                  <div class="d-flex column centered">
                    <img src="~@/assets/icons/medium.svg"/>
                    <div>10-19</div>
                  </div>

                  <div class="d-flex column centered">
                    <img src="~@/assets/icons/high.svg"/>
                    <div>20-39</div>
                  </div>

                  <div class="d-flex column centered">
                    <img src="~@/assets/icons/dangerous.svg"/>
                    <div>40+</div>
                  </div>
              </div>
            </div>
            <div class="item"> <div class="d-flex mb-1"><strong class="white">New API Docs</strong> <span class="ms-auto"><font-awesome-icon style="font-size: 1rem;" class="me-2" :icon="['fas', 'clock']" />{{ moment("10-14-2024 16:00:00").fromNow()}}</span></div>
              
              <p>Lately, we have seen a lot of interest in integrating with our API, and frankly, we didn’t feel like our docs provided the best experience when doing so. So we redid them! The docs now feature: field names, descriptions, return types, runnable examples, as well as some visual improvements. 
              </p>
              <p><router-link to="/docs/api">Check them out</router-link></p>
            </div>

            <!--
            <div class="item">
              <div class="d-flex mb-1"><strong class="white">Big performance improvements and clickable cells</strong><span class="ms-auto"><font-awesome-icon style="font-size: 1rem;" class="me-2" :icon="['fas', 'clock']" />{{ moment("09-30-2024 16:00:00").fromNow()}}</span></div>
              <p>
              The past month we have been hard at work completely redoing our data structures and revamping our database leading to a much, much faster experience when searching. It especially shows when doing bulk lookups, which previously was prone to suffer if the bulk contained domains with lots of data on them. In addition to this we are launching clickable cells. Lookup any ip or domain, try clicking the "num_emails", "num_domains", "domains_on_ip", or "num_subdomains", and you will see a popup table similar to those of a query. This should allow for much faster pivoting and iteration on a result set. 
              </p>
              <p>
              These changes are the first stepping stone to a more full fledged query language. Stay tuned for more! ❤️
              </p>
            </div>
            <div class="item">
              <div class="d-flex mb-1"><strong class="white">Goodbye on-demand scans, welcome mass-enrichment!</strong><span class="ms-auto"><font-awesome-icon style="font-size: 1rem;" class="me-2" :icon="['fas', 'clock']" />{{ moment("8-14-2024 16:00:00").fromNow()}}</span></div>
              <p>
              In line with the refocus outlined in our recent blog post, <a href="https://blog.webscout.io/visions-and-lessons-learned/">Visions and Lessons Learned</a>,
Webscout has gotten a brand-new look with a bunch of new features. Feel free to explore the
platform at your leisure or see some <router-link to="/docs/wiki/case/mapping-attack-surfaces">practical examples</router-link> of how it can be used. If you
want to go straight to the data, you can see how to craft your own queries in our <router-link to="/query">Query Reference</router-link>. By putting on-demand URL scanning to rest (at least for the time being), we can focus all our
effort on generating value for SOC teams, incident responders, and other cybersecurity
professionals seeking to enrich or make sense of vast amounts of web selectors in both structured
and unstructured data. Your feedback is invaluable to us, so please don't hesitate to send us your
thoughts at <a href="mailto:feedback@webscout.io">feedback@webscout.io</a>.
              </p>
            </div>
            -->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.title {
  font-size: 1.1rem;
  color: white;
  font-weight: bold;
}

.title.usage {
  font-size: 1.1rem;
  color: #A3A9B0;
  font-weight: normal;
}

.timeline {
  position: relative;
  padding-left: 3rem;
  max-height: 750px;
  overflow-y: scroll;
}

.timeline:after {
  content: '';
  position: absolute;
  width: .15rem;
  background-color: #29323E;
  top: 0;
  bottom: 0;
  left: 1rem;
  margin-left: -3px;
}

.table {
  width: 100%;
  table-layout: fixed;
}


.table th {
  color: white;
  font-weight: normal
}

.table tr {
  border-bottom: 1px solid #29323E;
}

.table tbody tr:last-child {
  border-bottom: 1px solid transparent;
}

.table td {
  color: #6D7785;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item {
  margin-bottom: 1rem;
  position: relative;
}

.timeline .item:after {
  content: '';
  position: absolute;
  width: .9rem;
  height: .9rem;
  top: 4px;
  left: calc(-2rem - .45rem - 2px);
  background-color: #29323E;
  border: 1px solid #384251;
  border-radius: 50%;
  z-index: 1;
}

.button {
  background-color: #1E252F;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  font-weight: bold;
  color: white;
  padding: .5rem 1rem;
}

.button.dark {
  background-color: #131820
}

.button:hover {
  background-color: #2B3440;
}

button {
  background-color: #1E252F;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  font-weight: bold;
  color: white;
  padding: .5rem 1rem;
}

button.dark {
  background-color: #131820
}

button:hover {
  background-color: #2B3440;
}

.query_history_cell:hover {
  background-color: #171c24;
  color: #cd317f;
}
.query_history_cell {
  cursor: pointer;
}

.clickable-item {
  cursor: pointer;
}

.clickable-item:hover {
  background-color: #171c24;
  border-radius: 5px;
}

#risk_gradients {
  img {
    height: 30px;
  }
  
  > div {
    margin-right: 2rem;
  }

  > div > div {
    font-size: .9rem;
  }
}

.dropzone {
  border: 4px dashed #29323e;
  border-style: ;
  justify-content: center;
  display: flex;
  height: 20rem;
  flex-direction: column;
  align-items: center;
}

.file-button {
  background-color: #285193
}

.file-button:hover {
  background-color: #385e9b
}

</style>
