<script setup>
import {defineProps, computed, watch, defineEmits, onMounted, ref, nextTick} from 'vue'
import Jumbo from '@/components/Docs/Jumbo.vue'
import DocSection from '@/components/Docs/Section.vue'
import CodeBlock from '@/components/Docs/CodeBlock.vue'
import JsonBlock from '@/components/Docs/JsonBlock.vue'

import StringParam from '@/components/Docs/Parameters/StringParam.vue'
import RequestRunner from '@/components/Docs/RequestRunner.vue'

import BasicAttribute from '@/components/Docs/Attributes/BasicAttribute.vue'

let request_domain = ref("reddit.com")

let get_query = computed(() => {
  return "/query/domain/" + request_domain.value + "/similar" 
}) 

</script>

<template>
  <div>
    <doc-section title="Similar domains">
      <template v-slot:left>
        <div class="mb-5">
          Sometimes it is desirable to find domains of a similar structure or with slight variance. This is especially useful when hunting for phishing sites which often deploy typosquatting strategies to trick unsuspecting victims into visiting them or divulging information.<br><br>
The response is a list of domains all of which have previously been online, and which all have some textual or visual similarity to the target domain. Included with the domain is the last resolved IP and its geo data. Note domains which have not been observed (by us) in the wild, will not be included in the response.
        </div>
      
        <div class="mb-5">
          <h4 class="mb-4">Request parameters</h4>

          <StringParam class="mb-3" name="Domain" :required="true" description="The domain you wish to find subdomains of" type="string" :value="request_domain" @value="(v) => request_domain = v" />
          <RequestRunner class="mt-5" :query="get_query" name="Domain information" method="GET"></RequestRunner>
        </div>

        <div>
          <h4 class="mb-4">Response attributes</h4>

          <BasicAttribute name="status" type="string" description="The status of the request's response" />
          <hr>

          <BasicAttribute name="data" type="object array" description="The requested information">

            <BasicAttribute name="domain" type="string" description="The requested domain" />
            <hr>

            <BasicAttribute name="ips" type="object array" description="The IPs which the domain resolves to, or have, if it currently doesn't resolve to any ip">
              <BasicAttribute name="ip" type="string" description="An ip the domain has recently resolved to" />
              <hr>
              <BasicAttribute name="risk_score" type="integer" description="Webscout's determination of the risk associated with the IP. Displayed in the form of a number between 0-100" />
              <hr>
              <BasicAttribute name="country" type="string" description="Two letter country code" />
              <hr>
              <BasicAttribute name="network_provider" type="string" description="The entity managing the ip address" />
            </BasicAttribute>
            <hr>

            <BasicAttribute name="num_emails" type="integer" description="The number of email addresses we have opserved on the domain" />
            <hr>

            <BasicAttribute name="num_subdomains" type="integer" description="The number of subdomains we have found on the domain" />
            <hr>

            <BasicAttribute name="registrant_org" type="string" description="The organization that registered the domain" />
            <hr>

            <BasicAttribute name="registrant_email" type="string" description="The email used to register the domain" />
            <hr>

            <BasicAttribute name="registration_date" type="string" description="The date the domain was registered" />
            <hr>

            <BasicAttribute name="expiration_date" type="string" description="The date the domain registration expires" />
            <hr>

            <BasicAttribute name="behavior" type="string array" description="Activity we have observed related to the domain or it's infrastructure" />

          </BasicAttribute>


        </div>

      </template>

      <template v-slot:right>
        <code-block title="/query/domain/:domain/similar" method="GET" dark/>
      
        <json-block title="Response">
{
"status": "success",
"data": [{
"domain": "reddit.ar",
"ips": [
{
"ip": "91.195.241.232",
"risk_score": 0,
"country": "DE",
"network_provider": "SEDO"
}
],
"num_emails": 0,
"num_subdomains": 16,
"registrant_org": "20298653525",
"registrant_email": null,
"registration_date": "2019-11-07T22:00:37.690515Z",
"expiration_date": "2024-11-07T00:00:00Z",
"behavior": null
}]
}
        </json-block>
      </template>
    </doc-section>
  </div>
</template>

<style scoped>
</style>
